<template>
  <teleport to="body">
    <transition name="backdrop">
      <div v-if="show" class="backdrop" @click="$emit('deny')">
        <div v-if="show" class="ui segment window" @click.stop>
          <slot></slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  emits: ['deny'],
};
</script>

<style scoped>
.window {
  display: block;
  position: absolute;
  top: 5vh;
  z-index: 201;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
}

/* Animations */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: all 0.3s ease-in-out;
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}
</style>
